function Footer() {
    return (
        <>
            <div class="flex flex-row font-normal pr-4 pl-4 md:pl-0 ">
                <div>
                    <a href="https://linkedin.com/in/adityasoni2019" target="_blank" rel="noreferrer" class="hover:underline hover:text-blue-500">LinkedIn</a>
                    <a href="mailto:adityasoni9727@gmail.com" class="ml-4 hover:underline hover:text-blue-500" target="_blank" rel="noreferrer" >Mail</a>
                    <a href="https://github.com/adityasoni2019" class="ml-4 hover:underline hover:text-blue-500" target="_blank" rel="noreferrer" >GitHub</a>
                    <span class="ml-4">
                        999 Forever
                    </span>
                </div>
            </div>
        </>
    )
}

export default Footer; 